<template>
  <div
      :data-testid="props.dataTestId"
      :class="props.class || twMerge([
      'flex items-center items-stretch',
      '*:rounded-none *:border-r-none',
      'last:*:border-r last:*:rounded-r-md first:*:rounded-l-md',
      props.appendClass
  ])">
    <slot/>
  </div>
</template>

<script setup lang="ts">
import {twMerge} from "tailwind-merge";

type Props = {
  class?: string,
  appendClass?: string,
  dataTestId?: string
}

const props = withDefaults(defineProps<Props>(), {
  dataTestId: 'molecule-button-group'
});
</script>
